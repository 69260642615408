.base-footer {
  margin-top: auto;
  font-weight: bold;
  padding: 1rem;
  font-size: 1rem;
  justify-content: center;
  color: white;
  background: #212529;
  display: flex;
}

.PersonalProjects {
  background: #d3d3d3;
}