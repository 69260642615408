/* CSS Formatting for all Game Project Pages */
.list {
  border-color: #212529;
  border-bottom: 6px outset #212529;
  display: flexbox;
}

.feature-list {
  display: flexbox;
  font-weight: bold;
  list-style-type: decimal;
}

.attribution-list {
  display: flexbox;
  font-size: 1.125rem;
}

.subheader {
  font-weight: bold;
  font-size: 1.5rem;
  color: black;
  display: flexbox;
  justify-content: left;
  text-align: left;
}

.attribution-subheader {
  font-weight: bold;
  font-size: 1.125rem;
  color: black;
  display: flexbox;
  justify-content: center;
}

.attribution-header {
  font-weight: bold;
  font-size: 1.5rem;
  color: black;
  display: flexbox;
  justify-content: center;
}

.about-content {
  font-size: 1.25rem;
  display: flexbox;
  flex-wrap: wrap;
  align-items: left;
  justify-content: left;
  padding-left: 1.5rem;
}

.about-grid {
  font-size: 2rem;
  display: flexbox;
  flex-wrap: wrap;
}

.info-item {
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
  max-inline-size: 100%;
  display: block;
}

.media-item {
  font-size: 1.25rem;
  padding-left: 3.5rem;
}

.responsive-iframe {
  justify-content: center;
  align-items: center;
  text-align: center;
  display: block;
  max-inline-size: 100%;
}

img {
  max-inline-size: 100%;
  display: block;
}

.image {
justify-content: center;
  align-items: center;
  text-align: center;
  display: block;
  max-inline-size: 100%;
}

.CartSmashInfo {
  padding-bottom: 3rem;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
}

.sublist {
  margin-left: 3em;
  list-style-type: disc;
  font-weight: 100;
}

.feature {
  padding-top: .75rem;
  padding-bottom: .75rem;
  padding-left: .25rem;
}

.feature-list {
  padding-left: 3rem;
}

.wrapper {
  display: grid;
  flex-wrap: wrap;
  padding-left: 1rem;
}

.image-item {
  display: flexbox;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-inline-size: 100%;
}