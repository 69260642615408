/* Formatting for Homepage */

.Global {
  /* Global handles formatting for all pages */
  background: #d3d3d3;
  font-family: "Roboto", sans-serif;
}

@font-face {
  font-family: 'Roboto', sans-serif;
  src: url("../RobotoCondensed-Regular.ttf") format("tff");
}

.CardsGrid {
  display: flex;
  text-align: center;
}

.base-header {
  margin-top: 1rem;
  font-weight: bold;
  padding: 1.75rem;
  font-size: 2rem;
  color: white;
  background: #212529;
  display: flex;
  justify-content: center;
  text-shadow: 2px 2px 4px #000000;
  border-radius: 5rem;
  margin-left: .5rem;
  margin-right: .5rem;
}

.media-item {
  text-align: center;
  padding: 1rem;
}

.info-item {
  font-size: 1.5rem;
}