.navbar {
    background-color: #1a1a1a;
    width: 100%;
    display: flex;
    font-family: "Roboto", sans-serif;
    text-shadow: 2px 2px 4px #000000;
}

@font-face {
    font-family: "Roboto", sans-serif;
    src: url("../../RobotoCondensed-Regular.ttf") format("tff");
}

.NavbarBrand {
    margin-left: 2rem;
}

.link {
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
}

.NavbarTitle {
    color: white;
    font-size: 1.25rem;
    font-weight: bold;
    padding-left: 2%;
}

.navbar-content {
    display: flex;
}

.mr-auto {
    margin-left: auto;
    padding-right: 3%;
}

.navbar-dropdown {
    font-weight: bold;
    padding-left: 2%;
}